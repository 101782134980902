import * as React from 'react';
import { Layout } from '../components';
import Header from '../components/faqpage/Header';
import Category from '../components/faqpage/Category';
import { categories } from '../utilities/faq';

const FaqPage = () => {
  return (
    <Layout title="Irorun ">
      <div className="faq container">
        <Header />
        {categories.map((category: any) => (
          <Category category={category} key={category.title} />
        ))}
      </div>
    </Layout>
  );
};

export default FaqPage;
